<template>
	<div class="hello">
		<div class="neirongbox">
			<!-- 轮播图 -->
			<div class="index_lunbo">
				<swiper :options="swiperOption" autoplay='true' ref="mySwiper" v-if="bannerList.length > 0">
					<swiper-slide class="swiper_sd" v-for="(item, index) in bannerList" :key="index">
						<div @click="jumpBannerFun(index)">
							<img :src="item.thumb" alt="" />
						</div>
					</swiper-slide>

					<!-- Optional controls -->
					<div class="swiper-pagination" slot="pagination"></div>
				</swiper>
			</div>

			<!-- 两个会员模块 -->
			<div class="memberbox">
				<div class="item" @click="buy_person">
					<img class="img_bg" :src="memberInfo.personal" />
					<!-- <img class="img_buy buy_person" @click="buy_person" src="../assets/imgs/buy01.gif" /> -->
				</div>
				<div class="item" @click="buy_business">
					<img class="img_bg" :src="memberInfo.enterprise" />
					<!-- <img class="img_buy buy_business" @click="buy_business" src="../assets/imgs/buy02.gif" /> -->
				</div>
			</div>

			<!--1个人购买-弹窗-->
			<div class="mymodal" v-if="gr_modal == true">
				<div class="gr_modal">
					<img class="close" @click="closeGr" src="../assets/imgs/close.png" />
					<img class="logoimg" :src="zikupersonal.topthumb" />
					<div class="con">
						<form action="" method="post">
							<!-- 信息 -->
							<div class="formbox">
								<div class="input_div">
									<div>您的邮箱</div>
									<input placeholder="请填写您的邮箱" type="email" v-model="email" />
								</div>
								<div class="input_div">
									<div>您的电话</div>
									<input placeholder="请填写您的电话" type="tel" v-model="mobile" />
								</div>
								<div class="input_div">
									<div>您的姓名</div>
									<input placeholder="请填写您的真实姓名" type="text" v-model="name" />
								</div>
								<pre class="tips">{{zikupersonal.bottomthumb}}</pre>
								<div class="xxtj" @click="personalSubmit()">提交</div>
							</div>
						</form>
					</div>

					<img style="display: block;margin: 30px auto;max-width: 1000px;" :src="zikupersonal.thumb_bottom" />
				</div>
			</div>

			<!-- 2企业购买-弹窗 -->
			<div class="qiyemodal" v-if="qy_modal == true">
				<div class="qy_modal">
					<img class="close" @click="closeQy" src="../assets/imgs/close.png" />
					<img class="logoimg" :src="zikuenterprise.basic_data.toptitle" />
					<div class="imgbox">
						<img :src="zikuenterprise.basic_data.kefuweixin" />
						<img :src="zikuenterprise.basic_data.authorization_desc" />
						<img :src="zikuenterprise.basic_data.bottomzhushi" />
						<img :src="zikuenterprise.basic_data.yellowtitle" />
					</div>

					<div class="con">
						<form action="" method="post">
							<div class="selebox">
								<div class="seleboxItem">
									<div class="lef">授权期限：</div>
									<div class="rig" v-for="(item,index) in zikuenterprise.term_list" :key="index"
										@click="qixian(index)">
										<div :class="qixianIndex == index ?' rig_item rig_active': ' rig_item'">{{item}}
										</div>
									</div>
								</div>
								<div class="seleboxItem">
									<div class="lef">被授权公司<br />注册资本：</div>
									<div class="rig" v-for="(item,index) in zikuenterprise.register_capital_list"
										:key="index" @click="ziben(index)">
										<div :class="zibenIndex == index ?' rig_item rig_active': ' rig_item'">{{item}}
										</div>
									</div>
								</div>
								<div class="seleboxItem">
									<div class="lef">公司性质：</div>
									<div class="rig" v-for="(item,index) in zikuenterprise.nature_list" :key="index"
										@click="xingzhi(index)">
										<div :class="xingzhiIndex == index ?' rig_item rig_active': ' rig_item'">
											{{item}}
										</div>
									</div>
								</div>
								<div class="seleboxItem selebox_price">
									<div class="lef">授权价格：</div>
									<div class="rig">¥{{memberPrice}}</div>
								</div>
							</div>

							<!-- 信息 -->
							<div class="formbox">
								<div class="input_div">
									<div>公司名称</div>
									<input placeholder="请填写公司全称" type="text" v-model="corporate_name" />
								</div>

								<div class="input_div">
									<div>公司税号</div>
									<input placeholder="请填写公司税号" type="text" v-model="company_tax_number" />
								</div>
								<div class="input_div">
									<div>您的邮箱</div>
									<input placeholder="请填写您的邮箱" type="email" v-model="email_qy" />
								</div>

								<div class="input_div">
									<div>您的电话</div>
									<input placeholder="请填写您的电话" type="tel" v-model="mobile_qy" />
								</div>

								<div class="input_div">
									<div>您的姓名</div>
									<input placeholder="请填写您的真实姓名" type="text" v-model="name_qy" />
								</div>
								<pre class="tips">{{zikuenterprise.basic_data.bottom_desc}}</pre>

								<div class="xxtj" @click="EnterpriseSubmit()">提交</div>
								<!-- <div class="agreebox">
									<input type="checkbox" v-model="check">阅读并同意 |
									<span style="cursor: pointer;" @click="seeShare(7)">《会员服务协议》</span>
									<span>和</span>
									<span style="cursor: pointer;" @click="toAgreement(6)">《授权许可协议》</span>
								</div> -->
							</div>
						</form>

					</div>

					<img style="display: block;margin: 10px auto;max-width: 1000px;"
						:src="zikuenterprise.basic_data.bottom_thumb" />
				</div>
			</div>


			<!-- 支付码弹窗 -->
			<div class="buy_box" v-if="isBuy == true">
				<div class="buy_rights">
					<div class="buy_close">
						<div class="el-icon-close" @click="closebuy"></div>
					</div>
					<!-- 支付码 -->
					<div class="buy_code">
						<div class="wx_icon"><img src="../assets/imgs/wx.png" /></div>
						<div class="ali_icon"><img src="../assets/imgs/zfb.png" /></div>
						<div class="wx"><img :src="precode_url_wx" /></div>
						<div class="ali"><img :src="Percode_url_al" /></div>
					</div>
					<p class="price">应付金额：<span>{{prepirce}}元</span></p>
				</div>
			</div>

			<!-- 购买成功弹窗 -->
			<div class="bg" v-if="tipStatus"></div>
			<div class="tips_wrap" v-show="tipStatus">
				<div class="tips">
					<div class="tips_h2">恭喜，购买成功</div>
					<!-- <div class="tips_p">可到我的主页查看</div> -->

					<div class="close" @click="tipStatusFun">
						<img src="../assets/imgs/close.svg" />
					</div>
				</div>
			</div>




		</div>

	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from "vue-awesome-swiper";
	import "swiper/css/swiper.css";
	export default {
		name: "index",
		components: {
			Swiper,
			SwiperSlide,
		},
		props: {
			msg: String,
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = () => {
				console.log("slide change");
			};
			return {
				onSwiper,
				onSlideChange,
			};
		},
		data() {
			return {
				bannerList: [],
				memberInfo: '',
				swiperOption: {
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
					},
					pagination: {
						el: ".swiper-pagination",
					},
				},

				gr_modal: false, //个人弹窗
				qy_modal: false, //企业弹窗
				// check: false,
				zikupersonal: '',
				zikuenterprise: '',
				isBuy: false,

				info: {},
				// erweima1: require("../assets/images/erweima@2x.png"),
				// erweima2: require("../assets/images/erweima@2x.png"),
				userInfo: {},
				Percode_url_al: "",
				precode_url_wx: "",
				pirce: "",

				tipStatus: false, //购买成功弹窗

				headers: {
					token: localStorage.getItem("access_token"),
				},

				type: 1, //类型 1个人 2企业

				// 个人提交type: 1
				name: '',
				mobile: '',
				email: '',
				prepirce: '',


				//企业提交 type2
				corporate_name: '', //公司全称
				company_tax_number: '', //公司税号
				mobile_qy: '', //电话
				email_qy: '', //邮箱
				name_qy: '', //真实姓名

				zibenIndex: 1, //注册资本
				qixianIndex: 1, //会员期限
				xingzhiIndex: 1, //公司性质
				memberPrice: 0, //会员购买价格

				order_no: "", //订单编号
				setintrerval: "", //接口轮询
			};
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.swiper;
			},
		},
		mounted() {
			// if (localStorage.getItem("access_token")) {}
			this.bannerIndex();
			this.MemberSite();
			// this.initFun();
			// this.qrcodeFun(this.type);

		},
		methods: {
			bannerIndex() {
				var that = this;
				this.$api.POST(this.$face.bannerIndex, "", function(res) {
					that.bannerList = res.data;
					// that.$nextTick(() => {
					// 	that.$swiper.slideTo(3, 1000, true);
					// });
				});
			},
			MemberSite() {
				var that = this;
				this.$api.POST(this.$face.zikuMemberSite, "", function(res) {
					that.memberInfo = res.data;
				});
			},

			// 选择（注册资本、会员期限、公司性质）
			ziben(index) {
				this.zibenIndex = index
				this.getPayPrice()
			},
			qixian(index) {
				this.qixianIndex = index
				this.getPayPrice()
			},
			xingzhi(index) {
				this.xingzhiIndex = index
				this.getPayPrice()
			},

			//关闭-购买成功弹窗
			tipStatusFun() {
				//清空查询的定时器
				if (this.timer) {
					window.clearInterval(this.timer);
					this.timer = null;
				}
				this.tipStatus = false;

				if (this.type == 1) { //个人
					this.gr_modal = false;
					this.email = ''
					this.mobile = ''
					this.name = ''
				} else { //企业
					this.qy_modal = false;
					this.corporate_name = ''
					this.company_tax_number = ''
					this.mobile_qy = ''
					this.email_qy = ''
					this.name_qy = ''
				}


				// this.$router.push({
				// 	name: "index",
				// });
			},
			//个人购买
			buy_person() {
				this.type = 1
				this.gr_modal = true
				this.zikupersonalFun()
			},
			//个人会员购买页基本信息
			zikupersonalFun() {
				var that = this;
				this.$api.POST(this.$face.zikupersonal, "", function(res) {
					that.zikupersonal = res.data;
				});
			},

			//关闭
			closeGr() {
				//清空查询的定时器
				if (this.timer) {
					window.clearInterval(this.timer);
					this.timer = null;
				}
				this.tipStatus = false

				this.gr_modal = false
				this.isBuy = false
				this.email = ''
				this.mobile = ''
				this.name = ''

			},
			closebuy() {
				this.isBuy = false
			},


			//个人提交
			personalSubmit() {
				// if (!this.headers.token) {
				// 	this.$utile.prompt("error", '请先登录');
				// 	return;
				// }
				if (this.email.trim() == '') {
					this.$utile.prompt("error", '请填写邮箱');
					return;
				}
				if (this.mobile.trim() == '') {
					this.$utile.prompt("error", '请填写您的电话');
					return;
				}
				if (this.name.trim() == '') {
					this.$utile.prompt("error", '请填写姓名');
					return;
				}

				this.qrcodeFun()
				//循环查询订单状态
				if (!this.timer) {
					this.timer = window.setInterval(() => {
						//执行内容
						setTimeout(this.searchOrder(this.order_no), 0);
					}, 5000);
				}
			},
			// 获取支付二维码--个人
			qrcodeFun() {
				var that = this;
				that.Percode_url_al = require('../assets/imgs/loading.gif');
				that.precode_url_wx = require('../assets/imgs/loading.gif');
				this.$api.POST(
					this.$face.getQrcode, {
						type: 1, //类型 1个人 2企业
						name: this.name,
						mobile: this.mobile,
						email: this.email,
					},
					function(res) {
						if (res.code == 200) {
							that.isBuy = true
							that.Percode_url_al = res.data.code_url_al;
							that.precode_url_wx = res.data.code_url_wx;
							that.prepirce = res.data.price;

							that.order_no = res.data.order_no // 订单编号

							// 订单编号
							// that.$emit("orderinfo", res.data.order_no);


							// localStorage.setItem("total", res.data.total); //存缓存
						} else {
							this.$message.error(res.msg);
						}
					}
				);
			},


			// 查询扫码支付--个人
			searchOrder() {
				var that = this;
				var data = {
					order_no: that.order_no,
				};
				this.$api.POST(this.$face.orderpaytype, data, function(res) {
					console.log("查询扫码支付", res);
					if (res.data.is_pay == 1) { //支付状态 0未支付 1已支付
						// 清空查询定时器
						if (that.timer) {
							window.clearInterval(that.timer);
							that.timer = null;
						}
						that.isBuy = false; //支付码弹窗关闭
						that.tipStatus = true
					}
				});
			},

			//清空定时器
			clearIntervalFun() {
				if (this.timer) {
					window.clearInterval(this.timer);
					this.timer = null;
				}
			},




			//企业提交
			EnterpriseSubmit() {
				if (this.corporate_name.trim() == '') {
					this.$utile.prompt("error", '请填写公司全称');
					return;
				}
				if (this.company_tax_number.trim() == '') {
					this.$utile.prompt("error", '请填写公司税号');
					return;
				}
				if (this.email_qy.trim() == '') {
					this.$utile.prompt("error", '请填写您的邮箱');
					return;
				}
				if (this.mobile_qy.trim() == '') {
					this.$utile.prompt("error", '请填写您的电话');
					return;
				}
				if (this.name_qy.trim() == '') {
					this.$utile.prompt("error", '请填写姓名');
					return;
				}
				// if (this.check == false) {
				// 	this.$utile.prompt("error", '请阅读并同意下方协议');
				// 	return;
				// }

				this.EnterpriseQrcodeFun()
				//循环查询订单状态
				if (!this.timer) {
					this.timer = window.setInterval(() => {
						//执行内容
						setTimeout(this.EnterpriseOrder(this.order_no), 0);
					}, 5000);
				}
			},
			// 获取支付二维码--企业
			EnterpriseQrcodeFun() {
				var that = this;
				that.Percode_url_al = require('../assets/imgs/loading.gif');
				that.precode_url_wx = require('../assets/imgs/loading.gif');
				this.$api.POST(
					this.$face.getQrcode, {
						type: 2, //类型 1个人 2企业
						register_capital: this.zibenIndex, //注册资本
						term: this.qixianIndex, //会员期限
						nature: this.xingzhiIndex, //公司性质

						name: this.name_qy, //姓名
						mobile: this.mobile_qy, //联系电话
						email: this.email_qy, //邮箱地址
						corporate_name: this.corporate_name, //公司名称
						company_tax_number: this.company_tax_number, //公司税号
					},
					function(res) {
						if (res.code == 200) {

							that.isBuy = true
							that.Percode_url_al = res.data.code_url_al;
							that.precode_url_wx = res.data.code_url_wx;
							that.prepirce = res.data.price;

							that.order_no = res.data.order_no // 订单编号
						} else {
							this.$message.error(res.msg);
						}
					}
				);
			},

			// 查询扫码支付--企业
			EnterpriseOrder() {
				var that = this;
				var data = {
					order_no: that.order_no,
				};
				this.$api.POST(this.$face.orderpaytype, data, function(res) {
					console.log("查询扫码支付", res);
					if (res.data.is_pay == 1) { //支付状态 0未支付 1已支付
						// 清空查询定时器
						if (that.timer) {
							window.clearInterval(that.timer);
							that.timer = null;
						}
						that.isBuy = false; //支付码弹窗关闭
						that.tipStatus = true
					}
				});
			},


			//企业购买
			buy_business() {
				this.type = 2
				this.qy_modal = true
				this.zikuenterpriseFun()
				this.getPayPrice()
			},

			//企业购买页基本信息
			zikuenterpriseFun() {
				var that = this;
				this.$api.POST(this.$face.zikuenterprise, "", function(res) {
					that.zikuenterprise = res.data;
				});
			},
			//获取企业会员购买价格
			getPayPrice() {
				var that = this;
				var data = {
					register_capital: that.zibenIndex, //	1	是	string	注册资本
					term: that.qixianIndex, //	1	是	string	会员期限
					nature: that.xingzhiIndex, //	1	是	string	公司性质
				}
				this.$api.POST(this.$face.getPayPrice, data, function(res) {
					that.memberPrice = res.data.price;
				});
			},
			//关闭-企业
			closeQy() {
				this.qy_modal = false
				this.corporate_name = '',
					this.company_tax_number = '',
					this.mobile_qy = '',
					this.email_qy = '',
					this.name_qy = '',
					this.zibenIndex = 1,
					this.qixianIndex = 1,
					this.xingzhiIndex = 1
			},


			// bannerClickFun() {
			// 	this.$router.push({
			// 		name: "dailyWord",
			// 	});
			// },
			
			//点击banner
			jumpBannerFun(index) {
				// console.log(index)
				if (index == 1) { //企业终身会员
					this.type = 2
					this.qy_modal = true
					this.zikuenterpriseFun()
					this.getPayPrice()
				} else { //个人终身会员
					this.type = 1
					this.gr_modal = true
					this.zikupersonalFun()
				}
			},

			// jumpBannerFun(url, param) {
			// 	if (param.links == 15) {
			// 		window.open(param.urls);
			// 		return;
			// 	}
			// 	let query;
			// 	if (param.font_cate != 0 || param.font_cate != 0 || param.series != 0) {
			// 		query = {};
			// 	} else {
			// 		query = {
			// 			id: param.links_id,
			// 		};
			// 	}
			// 	let routeData = this.$router.resolve({
			// 		name: url,
			// 		query: query,
			// 	});
			// 	window.open(routeData.href, "_blank");
			// },

			// toAgreement(id) {
			// 	let routeUrl = this.$router.resolve({
			// 		name: "userAgreement",
			// 		query: {
			// 			id: id,
			// 		},
			// 	});
			// 	window.open(routeUrl.href, "_blank");
			// },

			// seeShare(id) {
			// 	let routeUrl = this.$router.resolve({
			// 		path: "/agreement",
			// 		query: {
			// 			id: id,
			// 		},
			// 	});
			// 	window.open(routeUrl.href, "_blank");
			// },


		},


		//使用keepAlive缓存组件状态，页面切换路由会触发 activated 钩子函数。在 activated 中将 之前记录的位置赋值给引起滚动条滚动元素的 scrollTop
		activated() {
			console.log(this.scroll);

			this.$nextTick(() => {
				this.$parent.routerIndex = 1;

				if (
					localStorage.getItem("total") &&
					localStorage.getItem("access_token")
				) {
					this.total = localStorage.getItem("total");
				}
				localStorage.removeItem("clearAll");
			});
		},
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.hello {
		width: 100%;
	}

	/* banner */
	.swiper_item {
		display: block;
		width: 100%;
		/* max-height: 510px; */
		position: relative;
		cursor: pointer;
	}

	.swiper_sd img {
		width: 100%;
		cursor: pointer;
	}

	@keyframes swiper1 {
		0% {
			top: -10px;
			left: 30px;
		}

		50% {
			top: -20px;
			left: 60px;
		}

		100% {
			top: -10px;
			left: 30px;
		}
	}

	@keyframes swiper2 {
		0% {
			bottom: 0px;
			right: 200px;
		}

		50% {
			bottom: 20px;
			right: 240px;
		}

		100% {
			bottom: 0px;
			right: 200px;
		}
	}

	.index_lunbo {
		position: relative;
		width: 100%;
		/* height: 482px; */
		height: auto;
		overflow: hidden;
		margin-top: 133px;
		margin-bottom: 25px;
		border-radius: 14px;
	}

	.index_lunbo img {
		width: 100%;
		max-width: 100%;
		height: auto;
		/* object-fit: none; */
		display: block;
		/* image-rendering: -webkit-optimize-contrast; */
		/* Safari 和 Chrome */
		/* image-rendering: pixelated; */
		/* 其他浏览器 */
	}

	.memberbox {
		width: 100%;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;
		padding-bottom: 100px;
	}

	.memberbox .item {
		width: 49.53%;
		position: relative;
		overflow: hidden;
		border-radius: 15px;
		cursor: pointer;
	}

	.memberbox .item .img_bg {
		width: 100%;
		display: block;
	}

	.memberbox .item .img_buy {
		position: absolute;
		bottom: 20px;
		right: 50px;
		cursor: pointer;
	}


	.mymodal,
	.qiyemodal {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
		background: rgba(0, 0, 0, 0.3);
	}

	.gr_modal::-webkit-scrollbar,
	.qy_modal::-webkit-scrollbar {
		width: 0;
		background-color: #fff;
	}

	.gr_modal::-webkit-scrollbar-track,
	.qy_modal::-webkit-scrollbar-track {
		background-color: #fff;
	}

	.gr_modal::-webkit-scrollbar-thumb,
	.qy_modal::-webkit-scrollbar-thumb {
		background-color: #fff;
	}


	/*弹窗*/
	.mymodal .gr_modal,
	.qiyemodal .qy_modal {
		/* position: fixed;
			left: 50%;
			margin: -300px 0 0 -600px;
			top: 50%; */
		width: 100%;
		height: 100%;
		box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
		box-sizing: border-box;
	}

	.mymodal .gr_modal,
	.qiyemodal .qy_modal {
		background-image: url('../assets/imgs/grgm_bg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding-top: 50px;
		position: relative;
		overflow-y: scroll;
	}

	.qiyemodal .qy_modal {
		background-image: url('../assets/imgs/qygm_bg.png');
	}

	.mymodal .gr_modal .close,
	.qiyemodal .qy_modal .close {
		position: fixed;
		top: 50px;
		right: 60px;
		cursor: pointer;
	}

	.gr_modal .logoimg,
	.qy_modal .logoimg,
	.qy_modal .imgbox img {
		display: block;
		margin: 30px auto;
	}

	.qy_modal>.imgbox {
		width: 1200px;
		padding-bottom: 28px;
		border-bottom: 1px solid #EBE8E4;
		margin: auto;
		margin-bottom: 40px;
	}

	.mymodal .con,
	.qiyemodal .con {
		width: 800px;
		height: auto;
		padding: 30px;
		margin: auto;
		margin-top: 20px;
	}

	.qiyemodal .con {
		width: 1000px;
		padding: 0;
		padding-bottom: 50px;
	}

	.gr_modal .formbox .input_div input,
	.qy_modal .formbox .input_div input {
		border: none;
		outline: none;
		width: 100%;
		height: 58px;
		background: #FFFFFF;
		border: 1px solid #000066;
		border-radius: 10px;
		box-sizing: border-box;
		padding: 0 30px;
		margin-top: 20px;
		margin-bottom: 25px;
		font-size: 16px;
	}

	.qy_modal .formbox .input_div input {
		border: 1px solid #CC9966;
	}

	.gr_modal .formbox .input_div div,
	.qy_modal .formbox .input_div div {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
	}

	.gr_modal .formbox .input_div input::-webkit-input-placeholder,
	.qy_modal .formbox .input_div input::-webkit-input-placeholder {
		/* WebKit browsers */
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
	}

	.gr_modal .formbox .input_div input::-moz-placeholder,
	.qy_modal .formbox .input_div input::-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
	}

	.gr_modal .formbox .input_div input::-moz-placeholder,
	.qy_modal .formbox .input_div input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
	}

	.gr_modal .formbox .input_div input::-ms-input-placeholder,
	.qy_modal .formbox .input_div input::-ms-input-placeholder {
		/* Internet Explorer 10+ */
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #999999;
	}

	.gr_modal .formbox .tips,
	.qy_modal .formbox .tips {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		line-height: 30px;
	}

	.paycode .itembox,
	.paycode .itembox {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 80%;
		margin: auto;
		margin-top: 50px;
	}

	.paycode .price,
	.paycode .price,
	.buy_rights .price {
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
		text-align: center;
		margin-top: 60px;
	}

	.paycode .price span,
	.paycode .price span,
	.buy_rights .price span {
		color: #FF0000;
	}

	.paycode .itembox .item,
	.paycode .itembox .item {
		width: auto;
		text-align: center;
		margin: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.paycode .itembox .imgbox,
	.paycode .itembox .imgbox {
		width: 161px;
		height: 162px;
		background: url('../assets/imgs/cordbg.png') no-repeat;
		margin-bottom: 20px;
	}

	.paycode .itembox .imgbox img,
	.paycode .itembox .imgbox img {
		width: 140px;
		height: 140px;
		margin: 6px auto;
	}

	.paycode {
		padding-bottom: 50px;
	}

	.gr_modal .xxtj,
	.qy_modal .xxtj {
		border: none;
		outline: none;
		width: 300px;
		height: 60px;
		line-height: 60px;
		background: #000066;
		border-radius: 10px;
		text-align: center;
		margin: auto;
		font-size: 16px;
		font-weight: 400;
		color: #FFFFFF;
		display: block;
		margin-top: 50px;
		cursor: pointer;
	}

	.qy_modal .xxtj {
		background: #CC9966;
	}

	.agreebox {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		margin: 20px 0;
	}

	.agreebox a {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
	}

	.agreebox input {
		margin-right: 12px;
		cursor: pointer;
	}

	.qy_modal .selebox .seleboxItem {
		display: flex;
		align-items: center;
		margin-bottom: 40px;
	}

	.qy_modal .selebox .seleboxItem .lef {
		font-size: 16px;
		font-weight: 400;
		color: #000000;
		margin-right: 60px;
	}

	.qy_modal .selebox .seleboxItem .rig {
		display: flex;
		align-items: center;
	}

	.qy_modal .selebox .seleboxItem .rig .rig_item {
		cursor: pointer;
		background: #FFFFFB;
		border: 1px solid #E6E2D9;
		border-radius: 10px;
		box-sizing: border-box;
		font-size: 16px;
		font-weight: 400;
		color: #000000;
		text-align: center;
		width: 200px;
		height: 60px;
		line-height: 60px;
		position: relative;
		margin-right: 15px;
	}


	.qy_modal .selebox .seleboxItem:nth-child(1) .rig .rig_item,
	.qy_modal .selebox .seleboxItem:nth-child(3) .rig .rig_item {
		width: 300px;
	}

	.rig_active {
		border: 1px solid #CC9966 !important;
		background: #FDFAF6 !important;
		font-weight: bold !important;
		color: #333333 !important;
	}


	.rig_active::after {
		content: '';
		clear: both;
		display: block;
		position: absolute;
		right: 15px;
		background: url('../assets/imgs/qygm_icon.png') no-repeat;
		width: 17px;
		height: 12px;
		top: 24px;
	}

	.qy_modal .selebox .selebox_price .rig {
		font-size: 24px;
		font-weight: bold;
		color: #CC9966;
	}

	.buy_box {
		width: 800px;
		height: auto;
		background-color: #ffffff;
		position: fixed;
		top: 50%;
		left: 50%;
		z-index: 99999999;
		transform: translate(-50%, -50%);
		border-radius: 15px;
		overflow: hidden;
		box-shadow: 0 3px 6px 0 rgba(67, 67, 67, .3);
	}


	.buy_rights {
		width: auto;
		background: #f6f6f6;
		height: auto;
		padding: 30px 35px 50px;
	}


	.buy_code {
		width: 100%;
		height: 263px;
		background: #fff;
		margin-top: 17px;
		position: relative;
		border-radius: 10px;
		padding-bottom: 20px;
	}

	.buy_code .wx,
	.buy_code .ali {
		position: absolute;
		width: 161px;
		height: 162px;
		background-image: url("../assets/imgs/cordbg.png");
		text-align: center;
	}

	.buy_code img {
		width: 150px;
		height: 150px;
		margin: 6px auto;
	}

	.buy_code .wx {
		left: 133px;
		top: 62px;
	}

	.buy_code .ali {
		right: 133px;
		top: 62px;
	}




	.padd {
		margin: 0 5px;
		margin-top: -1px;
	}



	.el-empty__image {
		width: 60px;
	}

	.el-empty {
		padding: 25px 0;
	}

	.wx_icon {
		position: absolute;
		bottom: 25px;
		left: 190px;
	}

	.wx_icon img {
		width: 55px;
		height: 20px;
		margin: 0;
	}

	.ali_icon {
		position: absolute;
		bottom: 25px;
		left: 490px;
	}

	.ali_icon img {
		width: 69px;
		height: 20px;
		margin: 0;
	}

	.buy_close {
		text-align: right;
		font-size: 22px;
		color: #a0a1a1;
		cursor: pointer;
	}

	.tips_wrap {
		background-color: #fff;
		border-radius: 10px;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1000;
		width: 545px;
		height: 288px;
	}

	.tips {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}


	.tips_h2 {
		font-size: 20px;
		text-align: center;
		font-weight: bold;
		color: #595757;
	}


	.close {
		position: absolute;
		right: 22px;
		top: 20px;
	}

	.close img {
		width: 15px;
		height: 15px;
		opacity: 0.6;
	}

	.bg {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0.6;
		z-index: 999;
	}



	.hello>>>.swiper-pagination {
		width: auto;
		left: 50%;
		bottom: 40px;
		transform: translateX(-50%);
		background-color: rgba(255, 255, 255, 0.24);
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 12px;
		height: 19px;
		line-height: 19px;
	}

	.hello>>>.swiper-pagination-bullet {
		width: 12px;
		height: 4px;
		border-radius: 4px;
		background: #fff;
		margin: 0 3px;
	}

	.hello>>>.swiper-pagination-bullet-active {
		width: 26px;
		background: rgba(255, 255, 255, 0.8);
	}
</style>